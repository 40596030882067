body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sign-in-view__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: url("./assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.csvHistory {
  cursor: pointer;
}

.csvHistory:hover {
  background: #f1f1f1;
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 600px;
}

.MuiDataGrid-overlay {
  display: none !important;
}
