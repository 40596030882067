.scoreIndicator {
  position: relative;
  width: fit-content;
}

.scoreIndicator__wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scoreIndicator__score {
  font-size: 1.2rem;
  max-width: 50%;
}

.scoreIndicator__label {
  font-size: 0.6rem;
  text-transform: uppercase;
}

.scoreIndicator__marker {
  transition: 0.35s stroke-dashoffset;
  transform-origin: 50% 50%;
}

.scoreIndicator__runner {
  transform-origin: 50% 50%;
}

